/* eslint-disable max-len */
import React from 'react';
import styled from 'styled-components'

export const Github = props => (
  <SvgIcon {...props} viewbox="0 0 32 32">
    <path d="M16 0.395c-8.836 0-16 7.163-16 16 0 7.069 4.585 13.067 10.942 15.182 0.8 0.148 1.094-0.347 1.094-0.77 0-0.381-0.015-1.642-0.022-2.979-4.452 0.968-5.391-1.888-5.391-1.888-0.728-1.849-1.776-2.341-1.776-2.341-1.452-0.993 0.11-0.973 0.11-0.973 1.606 0.113 2.452 1.649 2.452 1.649 1.427 2.446 3.743 1.739 4.656 1.33 0.143-1.034 0.558-1.74 1.016-2.14-3.554-0.404-7.29-1.777-7.29-7.907 0-1.747 0.625-3.174 1.649-4.295-0.166-0.403-0.714-2.030 0.155-4.234 0 0 1.344-0.43 4.401 1.64 1.276-0.355 2.645-0.532 4.005-0.539 1.359 0.006 2.729 0.184 4.008 0.539 3.054-2.070 4.395-1.64 4.395-1.64 0.871 2.204 0.323 3.831 0.157 4.234 1.026 1.12 1.647 2.548 1.647 4.295 0 6.145-3.743 7.498-7.306 7.895 0.574 0.497 1.085 1.47 1.085 2.963 0 2.141-0.019 3.864-0.019 4.391 0 0.426 0.288 0.925 1.099 0.768 6.354-2.118 10.933-8.113 10.933-15.18 0-8.837-7.164-16-16-16z" />
  </SvgIcon>
)
export const Facebook = props => (
  <SvgIcon {...props} viewbox="0 0 32 32">
    <path d="M29 0h-26c-1.65 0-3 1.35-3 3v26c0 1.65 1.35 3 3 3h13v-14h-4v-4h4v-2c0-3.306 2.694-6 6-6h4v4h-4c-1.1 0-2 0.9-2 2v2h6l-1 4h-5v14h9c1.65 0 3-1.35 3-3v-26c0-1.65-1.35-3-3-3z" />
  </SvgIcon>
)
export const Linkedin = props => (
  <SvgIcon {...props} viewbox="0 0 32 32">
    <path d="M29 0h-26c-1.65 0-3 1.35-3 3v26c0 1.65 1.35 3 3 3h26c1.65 0 3-1.35 3-3v-26c0-1.65-1.35-3-3-3zM12 26h-4v-14h4v14zM10 10c-1.106 0-2-0.894-2-2s0.894-2 2-2c1.106 0 2 0.894 2 2s-0.894 2-2 2zM26 26h-4v-8c0-1.106-0.894-2-2-2s-2 0.894-2 2v8h-4v-14h4v2.481c0.825-1.131 2.087-2.481 3.5-2.481 2.488 0 4.5 2.238 4.5 5v9z" />
  </SvgIcon>
)
export const Instagram = props => (
  <SvgIcon {...props} viewbox="0 0 32 32">
    <path d="M16 2.881c4.275 0 4.781 0.019 6.462 0.094 1.563 0.069 2.406 0.331 2.969 0.55 0.744 0.288 1.281 0.638 1.837 1.194 0.563 0.563 0.906 1.094 1.2 1.838 0.219 0.563 0.481 1.412 0.55 2.969 0.075 1.688 0.094 2.194 0.094 6.463s-0.019 4.781-0.094 6.463c-0.069 1.563-0.331 2.406-0.55 2.969-0.288 0.744-0.637 1.281-1.194 1.837-0.563 0.563-1.094 0.906-1.837 1.2-0.563 0.219-1.413 0.481-2.969 0.55-1.688 0.075-2.194 0.094-6.463 0.094s-4.781-0.019-6.463-0.094c-1.563-0.069-2.406-0.331-2.969-0.55-0.744-0.288-1.281-0.637-1.838-1.194-0.563-0.563-0.906-1.094-1.2-1.837-0.219-0.563-0.481-1.413-0.55-2.969-0.075-1.688-0.094-2.194-0.094-6.463s0.019-4.781 0.094-6.463c0.069-1.563 0.331-2.406 0.55-2.969 0.288-0.744 0.638-1.281 1.194-1.838 0.563-0.563 1.094-0.906 1.838-1.2 0.563-0.219 1.412-0.481 2.969-0.55 1.681-0.075 2.188-0.094 6.463-0.094zM16 0c-4.344 0-4.887 0.019-6.594 0.094-1.7 0.075-2.869 0.35-3.881 0.744-1.056 0.412-1.95 0.956-2.837 1.85-0.894 0.888-1.438 1.781-1.85 2.831-0.394 1.019-0.669 2.181-0.744 3.881-0.075 1.713-0.094 2.256-0.094 6.6s0.019 4.887 0.094 6.594c0.075 1.7 0.35 2.869 0.744 3.881 0.413 1.056 0.956 1.95 1.85 2.837 0.887 0.887 1.781 1.438 2.831 1.844 1.019 0.394 2.181 0.669 3.881 0.744 1.706 0.075 2.25 0.094 6.594 0.094s4.888-0.019 6.594-0.094c1.7-0.075 2.869-0.35 3.881-0.744 1.050-0.406 1.944-0.956 2.831-1.844s1.438-1.781 1.844-2.831c0.394-1.019 0.669-2.181 0.744-3.881 0.075-1.706 0.094-2.25 0.094-6.594s-0.019-4.887-0.094-6.594c-0.075-1.7-0.35-2.869-0.744-3.881-0.394-1.063-0.938-1.956-1.831-2.844-0.887-0.887-1.781-1.438-2.831-1.844-1.019-0.394-2.181-0.669-3.881-0.744-1.712-0.081-2.256-0.1-6.6-0.1v0z" />
    <path d="M16 7.781c-4.537 0-8.219 3.681-8.219 8.219s3.681 8.219 8.219 8.219 8.219-3.681 8.219-8.219c0-4.537-3.681-8.219-8.219-8.219zM16 21.331c-2.944 0-5.331-2.387-5.331-5.331s2.387-5.331 5.331-5.331c2.944 0 5.331 2.387 5.331 5.331s-2.387 5.331-5.331 5.331z" />
    <path d="M26.462 7.456c0 1.060-0.859 1.919-1.919 1.919s-1.919-0.859-1.919-1.919c0-1.060 0.859-1.919 1.919-1.919s1.919 0.859 1.919 1.919z" />
  </SvgIcon>
)
export const Twitter = props => (
  <SvgIcon {...props} viewbox="0 0 32 32">
    <path d="M32 7.075c-1.175 0.525-2.444 0.875-3.769 1.031 1.356-0.813 2.394-2.1 2.887-3.631-1.269 0.75-2.675 1.3-4.169 1.594-1.2-1.275-2.906-2.069-4.794-2.069-3.625 0-6.563 2.938-6.563 6.563 0 0.512 0.056 1.012 0.169 1.494-5.456-0.275-10.294-2.888-13.531-6.862-0.563 0.969-0.887 2.1-0.887 3.3 0 2.275 1.156 4.287 2.919 5.463-1.075-0.031-2.087-0.331-2.975-0.819 0 0.025 0 0.056 0 0.081 0 3.181 2.263 5.838 5.269 6.437-0.55 0.15-1.131 0.231-1.731 0.231-0.425 0-0.831-0.044-1.237-0.119 0.838 2.606 3.263 4.506 6.131 4.563-2.25 1.762-5.075 2.813-8.156 2.813-0.531 0-1.050-0.031-1.569-0.094 2.913 1.869 6.362 2.95 10.069 2.95 12.075 0 18.681-10.006 18.681-18.681 0-0.287-0.006-0.569-0.019-0.85 1.281-0.919 2.394-2.075 3.275-3.394z" />
  </SvgIcon>
)
export const Codepen = props => (
  <SvgIcon {...props} viewbox="0 0 32 32">
    <path d="M29.555 11.501l-14-9.333c-0.336-0.224-0.774-0.224-1.109 0l-14 9.333c-0.278 0.185-0.445 0.498-0.445 0.832v9.333c0 0.334 0.167 0.647 0.445 0.832l14 9.333c0.168 0.112 0.361 0.168 0.555 0.168s0.387-0.056 0.555-0.168l14-9.333c0.278-0.185 0.445-0.498 0.445-0.832v-9.333c0-0.334-0.167-0.647-0.445-0.832zM15 20.465l-5.197-3.465 5.197-3.465 5.197 3.465-5.197 3.465zM16 11.798v-6.93l11.197 7.465-5.197 3.465-6-4zM14 11.798l-6 4-5.197-3.465 11.197-7.465v6.93zM6.197 17l-4.197 2.798v-5.596l4.197 2.798zM8 18.202l6 4v6.93l-11.197-7.465 5.197-3.465zM16 22.202l6-4 5.197 3.465-11.197 7.465v-6.93zM23.803 17l4.197-2.798v5.596l-4.197-2.798z" />
  </SvgIcon>
)
export const Paypal = props => (
  <SvgIcon {...props} viewbox="0 0 32 32">
    <path d="M29.063 9.644c-1.494 6.631-6.106 10.131-13.375 10.131h-2.419l-1.681 10.675h-2.025l-0.106 0.688c-0.069 0.456 0.281 0.863 0.738 0.863h5.175c0.613 0 1.131-0.444 1.231-1.050l0.050-0.262 0.975-6.181 0.063-0.337c0.094-0.606 0.619-1.050 1.231-1.050h0.769c5.012 0 8.938-2.038 10.088-7.925 0.431-2.238 0.269-4.137-0.712-5.55z" />
    <path d="M25.969 2.413c-1.481-1.688-4.163-2.413-7.587-2.413h-9.944c-0.7 0-1.3 0.506-1.406 1.2l-4.144 26.262c-0.081 0.519 0.319 0.988 0.844 0.988h6.144l1.544-9.781-0.050 0.306c0.106-0.694 0.7-1.2 1.4-1.2h2.919c5.731 0 10.219-2.325 11.531-9.063 0.038-0.2 0.075-0.394 0.1-0.581 0.387-2.487 0-4.188-1.35-5.719z" />
  </SvgIcon>
)
export const Patreon = props => (
  <SvgIcon {...props} viewbox="0 0 32 32">
    <path d="M0 15.56c0-7.454 6.054-14.44 14.036-15.44 5.72-0.666 10.046 1.53 13.040 4.392 2.792 2.66 4.456 6.056 4.856 10.050 0.332 4-0.532 7.46-2.86 10.72-2.328 3.2-6.92 6.72-12.64 6.72h-7.784v-15.362c0.068-3.394 1.2-6.324 5.32-7.72 3.594-1.066 7.786 0.93 9.050 4.724 1.32 4.060-0.6 6.788-2.86 8.452-2.28 1.664-5.8 1.664-8.12 0.066v5.26c1.52 0.74 3.46 0.94 4.86 0.86 5.040-0.72 8.98-3.58 10.64-7.92 1.72-4.58 0.52-9.92-3.060-13.24-4.32-3.54-9.060-4.4-14.1-1.94-3.54 1.8-6 5.46-6.6 9.46v17.358h-3.718l-0.060-16.44z" />
  </SvgIcon>
)


const SvgIcon = (props) => {
  const {
    disableClass,
    width,
    style,
    className,
    viewbox,
    children,
  } = props
  let Container = IconContainer
  if (disableClass) {
    Container = 'div'
  }
  return (
    <Container
      className={className}
      style={{ width, ...style }}
    >
      <Icon viewBox={viewbox}>
        {children}
      </Icon>
    </Container>
  )
}

const IconContainer = styled.div`
  opacity: 1;
  height: 50%;
  transition: opacity 2s;
  float: left;
`

const Icon = styled.svg`
  height: 100%;
  width: 100%;
  display: inline-block;
  stroke-width: 0;
  stroke: 'currentColor';
  fill: 'currentColor';
`
